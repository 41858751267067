var webhooks = {

  initialize: (webhook_id) => {
    const form = $(`.webhook-form[data-id="${webhook_id}"]`);

    $(form).find('select[name="webhook[key]"]').off('change').on('change', () => {
      const value = $(form).find('select[name="webhook[key]"]').val();

      if (value == 'automation') {
        $(form).find('.webhook-campaign-filter').hide();
      } else {
        $(form).find('.webhook-campaign-filter').show();
      }
    });
  }

};

document.addEventListener("turbolinks:load", function () {
  const webhookForms = document.querySelectorAll('.webhook-form');

  webhookForms.forEach((webhook) => {
    const webhook_id = $(webhook).data('id');
    webhooks.initialize(webhook_id);
  });
});

export default webhooks;