import { at } from "lodash";
import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useFields from "../fields/useFields";
import useActionGroups from "../action_groups/useActionGroups";
import useActions from "../actions/useActions";
import useContentLists from "../content_lists/useContentLists";
import useContentToggle from "../content_toggles/useContentToggle";
import useProducts from "../products/useProducts";
import useProductRecommendations from "../product_recommendations/useProductRecommendations";

const useElements = (Sections) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { ActionGroups } = useActionGroups();
  const { Fields } = useFields();
  const { ContentLists } = useContentLists();
  const { ContentToggle } = useContentToggle(Sections);
  const { Actions } = useActions();
  const { Products } = useProducts();
  const { ProductRecommendations } = useProductRecommendations();

  let variant = contextData.objects.variant;

  const Elements = {
    create: async function (
      parent_section,
      element_type,
      column,
      options,
      template_id = null,
      draggablePosition = null,
      updates = null,
      focusOnCreate = false,
      forceRender = false
    ) {
      let position =
        draggablePosition ||
        Object.values(variant.elements).filter(
          (element) =>
            element.section_id == parent_section.id &&
            element.column == column &&
            element.toBeDeleted !== true
        ).length + 1;

      builder.create(
        "elements",
        {
          variant_id: variant.id,
          section_id: parent_section.id,
          position: position,
          element_type: element_type,
          column: column,
          options: options,
          template_id: template_id,
        },
        {
          updates: updates,
          forceRender: forceRender,
          callback: (element) => {
            Elements.addChildrenToBuilder(element, true);

            if (element.element_type == "row") {
              let step = variant.steps[parent_section.step_id];

              Sections.create(step, element, null, true, undefined, undefined, undefined, (section) => {
                document.dispatchEvent(
                  new CustomEvent("selectObject", {
                    detail: {
                      object_type: 'sections',
                      object_id: section.id
                    },
                  })
                )
              });
            
            }

            if (focusOnCreate) {
              $([document.documentElement, document.body]).animate(
                {
                  scrollTop: $("#element" + element.id).offset().top - 150,
                },
                2000
              );
            }
          },
          skip_select: element_type == 'row'
        }
      );
    },

    duplicate: async function (og_element, payload, skip_history) {
      let updates = [];

      if (skip_history !== true) {
        let nextElements = Object.values(variant.elements).filter(
          (element) =>
            element.section_id == og_element.section_id &&
            element.column == og_element.column &&
            element.position > og_element.position &&
            element.toBeDeleted !== true
        );

        nextElements.forEach((nextElement) => {
          let update = {
            object_type: "elements",
            object_id: nextElement.id,
            setting_name: "[position]",
            value: nextElement.position + 1,
            old_value: nextElement.position,
          };
          updates.push(update);
          builder.update([{ ...update, skip_history: true }]);
        });
      }

      let attributes = {
        section_id: og_element.section_id,
        column: og_element.column,
        position:
          skip_history !== true ? og_element.position + 1 : og_element.position,
        element_type: og_element.element_type,
        html: og_element.html,
        options: og_element.options,
        duplicated: true,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      return new Promise((resolve, reject) => {
        builder.create("elements", attributes, {
          updates: updates,
          callback: async function (element) {
            try {
              Elements.addChildrenToBuilder(element, skip_history);

              // duplicate fields
              let element_parent_fields = Object.values(variant.fields).filter(
                (field) =>
                  field.element_id == og_element.id &&
                  field.toBeDeleted !== true &&
                  field.field_id == null
              );

              // duplicate content list items
              let content_list_items = Object.values(
                variant.content_list_items
              ).filter(
                (list_item) =>
                  list_item.element_id == og_element.id &&
                  list_item.toBeDeleted !== true
              );

              // duplicate content toggle items
              let content_toggle_items = Object.values(
                variant.content_toggle_items
              ).filter(
                (toggle_item) =>
                  toggle_item.element_id == og_element.id &&
                  toggle_item.toBeDeleted !== true
              );

              // duplicate actions
              let actions = Object.values(variant.actions).filter(
                (action) =>
                  action.element_id == og_element.id &&
                  action.toBeDeleted !== true
              );

              // duplicate action groups
              let action_groups = Object.values(variant.action_groups).filter(
                (action_group) =>
                  action_group.element_id == og_element.id &&
                  action_group.toBeDeleted !== true
              );

              // duplicate row section
              let sections = Object.values(variant.sections).filter(
                (section) =>
                  section.element_id == og_element.id &&
                  section.toBeDeleted !== true &&
                  section.content_toggle_item_id == null
              );

              let parent_section = variant.sections[element.section_id];

              // duplicate products
              let products = Object.values(variant.products).filter(
                (product) =>
                  product.element_id == og_element.id &&
                  product.toBeDeleted !== true
              );

              // duplicate product recommendations
              let product_recommendations = Object.values(
                variant.product_recommendations
              ).filter(
                (product_recommendation) =>
                  product_recommendation.element_id == og_element.id &&
                  product_recommendation.toBeDeleted !== true
              );

              await Promise.all([
                ...element_parent_fields.map((field) => {
                  return Fields.duplicate(
                    field,
                    {
                      element_id: element.id,
                    },
                    true
                  );
                }),
                ...content_list_items.map((list_item) => {
                  return ContentLists.duplicate(
                    list_item,
                    {
                      element_id: element.id,
                    },
                    true
                  );
                }),
                ...content_toggle_items.map((toggle_item) => {
                  return ContentToggle.duplicate(
                    toggle_item,
                    {
                      element_id: element.id,
                    },
                    true
                  );
                }),
                ...actions.map((action) => {
                  return Actions.duplicate(
                    action,
                    {
                      element_id: element.id,
                      contactable_id: element.id,
                      contactable_type: "Element",
                    },
                    true
                  );
                }),
                ...action_groups.map((action_group) => {
                  return ActionGroups.duplicate(
                    action_group,
                    {
                      element_id: element.id,
                    },
                    true
                  );
                }),
                ...sections.map((section) => {
                  return Sections.duplicate(
                    section,
                    {
                      element_id: element.id,
                      step_id: parent_section.step_id,
                    },
                    true
                  );
                }),
                ...products.map((product) => {
                  return Products.duplicate(
                    product,
                    {
                      element_id: element.id,
                    },
                    true
                  );
                }),
                ...product_recommendations.map((product_recommendation) => {
                  return ProductRecommendations.duplicate(
                    product_recommendation,
                    {
                      element_id: element.id,
                    },
                    true
                  );
                }),
              ])

              resolve(element);
            } catch (error) {
              reject(error);
            }
          },
          skip_history: skip_history,
          skip_select: true,
        });
      })
    },

    remove: (removed_element) => {
      let message =
        "Are you sure you want to remove this element? Once your changes are saved, it will be irreversibly deleted.";
      let updates = [];

      let nextElements = Object.values(variant.elements).filter(
        (element) =>
          element.toBeDeleted !== true &&
          element.section_id == removed_element.section_id &&
          element.column == removed_element.column &&
          element.position > removed_element.position
      );

      nextElements.forEach((element) => {
        let update = {
          object_type: "elements",
          object_id: element.id,
          setting_name: "[position]",
          value: element.position - 1,
          old_value: element.position,
        };

        updates.push(update);
        builder.update([{ ...update, skip_history: true }]);
      });

      builder.remove("elements", removed_element.id, message, updates);

      const elementContainer = $(`.cf-cta-item-container[data-section-id="${removed_element.section_id}"][data-section-column="${removed_element.column}"]`);
    },

    moveUp: (moved_element) => {
      let updates = [];
      let elements = Object.values(variant.elements)
        .filter(
          (e) =>
            e.toBeDeleted !== true &&
            e.section_id == moved_element.section_id &&
            e.column == moved_element.column
        )
        .sort((a, b) => a.position - b.position);
      let new_value = moved_element.position - 1;

      if (elements.filter((e) => e.position == new_value)[0]) {
        updates.push({
          object_type: "elements",
          object_id: moved_element.id,
          setting_name: "[position]",
          value: new_value,
          old_value: moved_element.position,
        });

        elements
          .filter((e) => e.position == moved_element.position - 1)
          .forEach((element) => {
            updates.push({
              object_type: "elements",
              object_id: element.id,
              setting_name: "[position]",
              value: element.position + 1,
              old_value: element.position,
            });
          });

        builder.update(updates);
      }
    },

    moveDown: (moved_element) => {
      let updates = [];
      let elements = Object.values(variant.elements)
        .filter(
          (e) =>
            e.toBeDeleted !== true &&
            e.section_id == moved_element.section_id &&
            e.column == moved_element.column
        )
        .sort((a, b) => a.position - b.position);
      let new_value = moved_element.position + 1;

      if (elements.filter((e) => e.position == new_value)[0]) {
        updates.push({
          object_type: "elements",
          object_id: moved_element.id,
          setting_name: "[position]",
          value: new_value,
          old_value: moved_element.position,
        });

        elements
          .filter((e) => e.position == moved_element.position + 1)
          .forEach((element) => {
            updates.push({
              object_type: "elements",
              object_id: element.id,
              setting_name: "[position]",
              value: element.position - 1,
              old_value: element.position,
            });
          });

        builder.update(updates);
      }
    },

    addChildrenToBuilder: (element, skip_history) => {
      builder.addObjectToBuilder("elements", element, skip_history);
      ctas.builder.fonts.load_used_fonts(`#element${element.id}`);

      if (Object.keys(element).includes("fields")) {
        Object.values(element.fields).forEach((field) => {
          builder.addObjectToBuilder("fields", field, skip_history);

          if (Object.keys(field).includes("field_options")) {
            Object.values(field.field_options).forEach((field_option) => {
              builder.addObjectToBuilder(
                "field_options",
                field_option,
                skip_history,
              );
            });
          }
        });
      }

      if (Object.keys(element).includes("content_list_items")) {
        Object.values(element.content_list_items).forEach((list_item) => {
          builder.addObjectToBuilder(
            "content_list_items",
            list_item,
            skip_history
          );
        });
      }

      if (Object.keys(element).includes("products")) {
        Object.values(element.products).forEach((product) => {
          builder.addObjectToBuilder("products", product, skip_history);

          Object.values(product.product_variants).forEach((product_variant) => {
            builder.addObjectToBuilder(
              "product_variants",
              product_variant,
              skip_history
            );
          });
        });
      }

      if (Object.keys(element).includes("content_toggle_items")) {
        Object.values(element.content_toggle_items).forEach((list_item) => {
          builder.addObjectToBuilder(
            "content_toggle_items",
            list_item,
            skip_history
          );
        });
      }

      if (element.actions) {
        Object.values(element.actions).forEach((action) => {
          builder.addObjectToBuilder("actions", action, skip_history);
        });
      }

      if (Object.keys(element).includes("sections")) {
        Object.values(element.sections).forEach((section) => {
          builder.addObjectToBuilder("sections", section, skip_history);

          Object.values(section.elements).forEach((element) => {
            Elements.addChildrenToBuilder(element, true);
          });
        });
      }
    },

    updateFieldCount: (element) => {
      let updates = [];
      let field_count = 0;

      let preset_fields = [
        "name",
        "lastname",
        "email",
        "phone",
        "url",
        "company-name",
        "company-title",
        "city",
        "state",
        "country",
        "address",
        "zip-code",
      ];

      preset_fields.forEach((preset_field) => {
        if (element.options["show-" + preset_field] == "true") {
          field_count += 1;
        }
      });

      field_count += Object.values(contextData.objects.variant.fields).filter(
        (field) =>
          field.element_id == element.id &&
          field.toBeDeleted !== true &&
          field.field_type &&
          field.field_type !== "hidden"
      ).length;

      updates.push({
        object_type: "elements",
        object_id: element.id,
        setting_name: "[options][visible-fields]",
        value: field_count,
        skip_history: true,
      });

      builder.update(updates);
    },

    froalaEditor: function (website, element, target) {
      const FroalaEditor = froalaExtend(contextData.objects.website);

      let element_tag = document.querySelector(
        '#builder .cf-element[data-element-id="' + element.id + '"]'
      );
      let container = document.querySelector(
        '#builder .cf-section[section-id="' +
          element.section_id +
          '"] .cf-cta-item-container[data-section-id="' +
          element.section_id +
          '"][data-section-column="' +
          element.column +
          '"]'
      );
      let selectLabelPopover = element_tag.querySelector(
        '.select-label-popover[data-object_type="elements"][data-object_id="' +
          element.id +
          '"]'
      );

      if (target && !target.id) {
        target.id = "selected";
      }

      let brand_color_vibrant = "#0072f7";
      let brand_color_dark = "#000";
      let brand_color_light = "#fff";

      if (website && website.data) {
        brand_color_vibrant =
          website.data["brand_color_vibrant"] || brand_color_vibrant;
        brand_color_dark =
          website.data["brand_color_dark"] || brand_color_dark;
        brand_color_light =
          website.data["brand_color_light"] || brand_color_light;
      }

      let toolbarButtons;

      toolbarButtons = [
        "paragraphFormat",
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "insertLink",
        "fontFamily",
        "fontSize",
        "align",
        "lineHeight",
        "textColor",
        "backgroundColor",
        "customHTML",
      ];

      if (["button", "form"].includes(element.element_type)) {
        toolbarButtons.filter((button) => ['paragraphFormat', 'backgroundColor', 'insertLink', 'align'].includes(button) == false)
      }

      let toolbar_buttons_img = ['filestackReplace', 'imageAlign', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', 'imageStyle', 'imageSize', '-', 'imageRemove', 'imageAlt', 'imageDisplay'];

      if (element.element_type == "image") {
        // prettier-ignore
        toolbarButtons = ['align', 'filestack', 'insertLink', 'clearFormatting', 'customHTML'];
        toolbar_buttons_img = toolbar_buttons_img.filter(
          (tool) => tool !== "imageRemove"
        );
      }

      // prettier-ignore
      let color_codes = [
        '#FFFFFF', '#F2F2F2', '#bdc3c7', '#7f8c8d', '#6C7A89',
        '#3498db', '#0075ff', '#2C82C9', '#003FFF', '#475577', 
        '#34495e', '#28324E', '#191A1B', '#000000', '#4ECDC4', 
        '#1abc9c', '#2ecc71', '#27ae60', '#16a085', 
        '#019875', '#1E824C', '#F5D76E', '#FAC51C', 
        '#FBA026', '#e67e22', '#E25041', '#B8312F', 
        '#96281B', '#f624a2', '#BF55EC', '#9A12B3', 
        '#663399', brand_color_light, brand_color_vibrant, 
        brand_color_dark, 'REMOVE'
      ];

      // prettier-ignore
      let font_sizes = ['None', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '60', '72', '96'];

      // prettier-ignore

      let editor = element_tag.querySelector(".froala-editor");

      const updateBuilder = (new_value, skip_history) => {
        builder.update([
          {
            object_type: "elements",
            object_id: element.id,
            setting_name: "[html]",
            value: new_value,
            skip_history: skip_history ? skip_history : false,
          },
        ]);
        dispatchCustomEvent("selectObject", {
          object_type: "elements",
          object_id: element.id,
          editing: false,
        });
      };

      let froalaOptions = {
        attribution: false,
        toolbarInline: true,
        initOnClick: false,
        toolbarSticky: false,
        toolbarVisibleWithoutSelection: true,
        toolbarButtons: {
          'moreText': {
            'buttons': toolbarButtons,
            'buttonsVisible': 6
          },
          'misc': {
            'buttons': [
              "filestack",
              "personalize",
              "clearFormatting",
            ],
            'buttonsVisible': 3
          }
        }
        ,
        charCounterCount: false,
        keepFormatOnDelete: true,
        enter: FroalaEditor.ENTER_BR,
        fontSize: font_sizes,
        fontFamily: builder_fonts,
        fontFamilySelection: true,
        imageEditButtons: toolbar_buttons_img,
        imageResize: false,
        colorsBackground: color_codes,
        colorsStep: 8,
        colorsText: color_codes,
        pastePlain: true,
        key: "2J1B10dA5F5A3A4E3E3J3C-22VKOG1FGULVKHXDXNDXc2a1Kd1SNdF3H3A8D7D4F4B3E3E2A10==",
        linkStyles: {
          "cf-link-no-underline": "No underline",
          "cf-link-blue": "Blue hyperlink",
        },
        linkList: [],
        events: {
          'initialized':  function () {
            let thisFroala = this;
            $('.fr-element[contenteditable="true"]').attr("data-gramm", "false");
  
            $('.fr-command[data-cmd="fontFamily"]').on('click', function() {
              ctas.builder.fonts.froala_dropdown();
            });
          },
          'commands.before': function (cmd, e) {
            if (
              cmd == "customHTML" &&
              builder.displayFeatureGate(e, "Custom HTML")
            ) {
              this.events.trigger('blur', [], true);
              
              let modal_trigger = document.querySelector(
                'button[object_type="elements"][object_id="' +
                  element.id +
                  '"][setting_name="[html]"]'
              );
              if (modal_trigger) {
                modal_trigger.click();
              }
            }
    
            if (cmd == "filestack" || cmd == "filestackReplace") {
              froala.filestackUploading = true;
            }
          },
          'commands.after': function (cmd, e) {
            // list of commands that change content and should enable builder save button
            const commandsList = [
              'linkRemove',
              'linkInsert',
              'imageAlign',
              'imageSetAlt',
              'imageStyle',
              'imageSetSize',
            ]

            if (commandsList.includes(cmd)) {
              froala.events.trigger('blur', [], true);
            }
          },
          'blur': function () {
            if (froala.filestackUploading !== true) {
              froala.html.cleanEmptyTags();
              let html = froala.html.get(true);
              html = html.replace('id="selected"', "");


              if (html) {
                console.log('froala.undo_index', froala.undo_index)
                updateBuilder(html, froala.undo_index <= 1);
                froala.destroy();
              }
            }
          },
          'focus': function () {
            selectLabelPopover.style.display = "none";
          }
        }
      };

      let froala = new FroalaEditor(editor, froalaOptions);

      let selected;
      if (froala) {
        selected = $(froala.$el).find("#selected")[0];
      }

      if (selected) {
        froala.events.trigger('focus', [], true);
        froala.events.trigger('selection.setAtEnd', [], true);
        froala.events.trigger('selection.restore', [], true);
        selected.removeAttribute("id");
      }

      window.sortables.forEach((sortable, i) => {
        if (
          sortable.section_id == element.section_id &&
          sortable.column == element.column
        ) {
          sortable.destroy();
        }
      });
      window.sortables = [];
    },
  };

  let currentHook = Elements;
  return { Elements, currentHook };
};

export default useElements;
