/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
// require("channels")
require("chartkick");
import "stylesheets/application.scss"

global.$ = global.jQuery = require("jquery");
global.$cf = $;

import "bootstrap/dist/js/bootstrap";
import Chartkick from "chartkick";
import Sortable, { MultiDrag, Swap } from "sortablejs";
import "../src/global_helpers.js";
import "../src/bootstrap.file-input.js";
import "../src/codemirror.js";
import "../src/fontawesome-iconpicker.js";
import "../src/jquery.connections.js";
import "../src/jquery.froala.js";
import "../src/jquery.froala-align.js";
import "../src/jquery.froala-code_view.js";
import "../src/jquery.froala-colors.js";
import "../src/jquery.froala-link.js";
import "../src/jquery.froala-lists.js";
import "../src/jquery.froala-paragraph_format.js";
import "../src/jquery.froala-paragraph_style.js";
import "../src/jquery.froala-url.js";
import "../src/jquery.minicolors.js";
import "../src/jquery.placeholder.js";
import "../src/jquery.timeago.js";
import "../src/lodash.core.min.js";

import accounts from "packs/accounts";
import actions from "packs/actions";
import areas from "packs/areas";
import broadcasts from "packs/broadcasts";
import broadcasts_ctas from "packs/broadcasts_cta";
import broadcasts_groups from "packs/broadcasts_groups";
import campaign_templates from "packs/campaign_templates";
import cards from "packs/cards";
import conditions from "packs/conditions";
import conditions_groups from "packs/conditions_groups";
import contacts from "packs/contacts";
import cta_groups from "packs/cta_groups";
import ctas from "packs/ctas";
import domains from "packs/domains";
import groups from "packs/groups";
import integrations from "packs/integrations";
import onboard from "packs/onboard";
import reports from "packs/reports";
import subscriptions from "packs/subscriptions";
import templates from "packs/templates";
import uploads from "packs/uploads";
import users from "packs/users";
import variants from "packs/variants";
import webhooks from "packs/webhooks";
import websites from "packs/websites";
import wistia from "packs/wistia";
import services from "packs/services";

window.actions = actions;
window.areas = areas;
window.broadcasts = broadcasts;
window.broadcasts_ctas = broadcasts_ctas;
window.broadcasts_groups = broadcasts_groups;
window.campaign_templates = campaign_templates;
window.conditions = conditions;
window.conditions_groups = conditions_groups;
window.contacts = contacts;
window.cta_groups = cta_groups;
window.ctas = ctas;
window.domains = domains;
window.groups = groups;
window.integrations = integrations;
window.onboard = onboard;
window.reports = reports;
window.Sortable = Sortable;
window.subscriptions = subscriptions;
window.templates = templates;
window.uploads = uploads;
window.users = users;
window.variants = variants;
window.webhooks = webhooks;
window.websites = websites;

window.getUrlParameter = function (sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
};

window.numberWithCommas = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

window.csrfToken = function () {
  var csrf_token;
  var metaTag = document.querySelector('meta[name="csrf-token"]');

  if (metaTag) {
    csrf_token = metaTag.getAttribute("content");
  }
  return csrf_token;
};

window.dispatchCustomEvent = function (event_name, payload) {
  document.dispatchEvent(
    new CustomEvent(event_name, { detail: { ...payload } })
  );
};

window.isNumeric = function (str) {
  if (typeof str != "number" && typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

window.onVisible = (domElement, callback) => {
  if (domElement) {
    new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          callback(domElement);
          observer.disconnect();
        }
      });
    }).observe(domElement);
  }
}

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
