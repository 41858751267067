class ShopifyGraphqlClient extends BaseClient {
  constructor(props) {
    super();

    this.endpoint = `https://${props.store_name}.myshopify.com/api/2024-07/graphql.json`
    this.clientOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": props.token,
      }
    }
  }

  // Methods

  isWithinOnlineStore = () => {
    if (window.Shopify && window.Shopify.routes) {
      return true;
    }

    return super.isWithinOnlineStore();
  }

  getProduct = async (handle) => {
    const options = { ...this.clientOptions, body: JSON.stringify({ query: this.getProductQuery(handle) })}

    return new Promise((resolve, reject) => fetch(this.endpoint, options)
      .then(response => {
        response.json().then((resp) => resolve(resp.data.productByHandle)).catch(reject);
      }).catch(reject));
  }

  getProducts = async (args, products = [], cursor = undefined) => {
    const options = {
      ...this.clientOptions,
      body: JSON.stringify({
        query: this.getProductsQuery({
          limit: args?.limit || 75,
          cursor: cursor,
          ...args,
        }),
      }),
    };

    return new Promise((resolve, reject) => fetch(this.endpoint, options)
      .then(response => {
        response.json().then(resp => {
          let response_data = resp?.data?.products

          if (!response_data) {
            resolve(products)
          }

          let page_info = response_data.pageInfo
          let next_page = page_info.hasNextPage ? page_info.endCursor : null
          products = products.concat(response_data.edges.map(edge => new ShopifyAdapter(edge.node)))

          if (typeof window.convertflow == 'undefined') {
            // If ConvertFlow is not present means we're on the builder.
            if (cursor === undefined) {
              window.cf_shopify_products = products;
            } else {
              window.cf_shopify_products = [...new Map(
                [...window.cf_shopify_products, ...products].map((item) => [item.id, item])
              ).values()];
            }

            dispatchCustomEvent("cf_shopify_products_change", {
              products: window.cf_shopify_products,
            });
          }

          if (next_page && (!args.limit || products.length < args.limit)) {
            this.getProducts(args, products, next_page).then(resolve).catch(reject)
          } else {
            resolve(products)
          }
        }).catch(reject);
      }).catch(reject));
  }

  getProductRecommendations = async (productId, args) => {
    const options = { ...this.clientOptions, body: JSON.stringify({ query: this.getProductRecommendationsQuery(productId, args) })}

    return new Promise((resolve, reject) => fetch(this.endpoint, options)
      .then(response => {
        response.json().then((resp) => resolve(resp.data.productRecommendations)).catch(reject);
      }).catch(reject));
  }

  getCollectionProducts = async (collectionId, args) => {
    const options = {
      ...this.clientOptions,
      body: JSON.stringify({ query: this.getCollectionProductsQuery(collectionId, { limit: args.limit || 100, ...args }) })
    }

    return new Promise((resolve, reject) => fetch(this.endpoint, options)
      .then(response => {
        response.json().then((resp) => resolve(resp.data.collectionByHandle.products.nodes)).catch(reject);
      }).catch(reject));
  }

  cartCreate = async () => {
    const options = { ...this.clientOptions, body: JSON.stringify({ query: this.cartCreateMutation() })}

    return new Promise((resolve, reject) => fetch(this.endpoint, options)
      .then(response => {
        response.json().then((resp) => resolve(resp.data.cartCreate.cart)).catch(reject);
      }).catch(reject));
  }

  cartAddProduct = async (cartId, products) => {
    const products_with_variant_prefix = products.map((product) => {
      let parsedID = product.merchandiseId.toString().includes('gid://shopify/ProductVariant/')
        ? product.merchandiseId
        : `gid://shopify/ProductVariant/${product.merchandiseId}`

      let parsedPlanID = product.sellingPlanId
        ? product.sellingPlanId
            .toString()
            .includes("gid://shopify/SellingPlan/")
          ? product.sellingPlanId
          : `gid://shopify/SellingPlan/${product.sellingPlanId}`
        : undefined;

      return { ...product,
        merchandiseId: parsedID,
        id: parsedID,
        sellingPlanId: parsedPlanID,
      }
    })

    const products_without_variant_prefix = products.map((product) => {
      let parsedID = product.merchandiseId.toString().includes('gid://shopify/ProductVariant/')
        ? product.merchandiseId.replace('gid://shopify/ProductVariant/', '')
        : product.merchandiseId

      return { ...product,
        merchandiseId: parsedID,
        id: parsedID,
        selling_plan: product.sellingPlanId ? product.sellingPlanId.replace('gid://shopify/SellingPlan/', '') : undefined
      }
    })

    const options = {
      ...this.clientOptions,
      body: JSON.stringify({ query: this.cartLinesAddMutation(cartId, products_with_variant_prefix) })
    }

    if (this.isWithinOnlineStore()) {
      // Add to Shopify Cart Browser Session when in Shopify Store
      return new Promise((resolve, reject) =>
        resolve(
          fetch(
            `${
              window.Shopify && window.Shopify.routes
                ? window.Shopify.routes.root
                : "/"
            }cart/add.js`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ items: products_without_variant_prefix }),
            }
          )
        )
      );
    } else {
      return new Promise((resolve, reject) => fetch(this.endpoint, options)
        .then(response => {
          response.json().then((resp) => {
            let parsedAllProducts = resp.data.cartLinesAdd.cart;
            let productHandlesAndTitles = parsedAllProducts.lines.edges.map((line) => {
              return {
                ...line.node.merchandise,
                line_id: line.node.id,
                quantity: line.node.quantity,
                price: parseFloat(line?.node?.cost?.totalAmount?.amount),
                sellingPlan: line.node.sellingPlanAllocation?.sellingPlan,
              }
            });
            let uniqueProductList = [...new Set(productHandlesAndTitles)];
            parsedAllProducts.lines = uniqueProductList

            resolve(parsedAllProducts)
          }).catch(reject);
        }).catch(reject));
    }
  }

  cartRemoveProduct = async (cartId, products) => {
    const options = {
      ...this.clientOptions,
      body: JSON.stringify({
        query: this.cartLinesRemoveMutation(cartId, products)
      })
    }

    return new Promise((resolve, reject) => fetch(this.endpoint, options)
      .then(response => {
        response.json().then((resp) => {
          let parsed = resp.data.cartLinesRemove.cart;
          let productHandlesAndTitles = parsed.lines.edges.map((line) => {
            return {
              ...line.node.merchandise,
              line_id: line.node.id,
              quantity: line.node.quantity,
              sellingPlan: line.node.sellingPlanAllocation?.sellingPlan,
              price: parseFloat(line?.node?.cost?.totalAmount?.amount),
            }
          });
          let uniqueProductList = [...new Set(productHandlesAndTitles)];
          parsed.lines = uniqueProductList

          resolve(parsed)
        }).catch(reject);
      }).catch(reject)
    );
  }

  cartApplyDiscountCode = async (cartId, discountCode) => {
    const options = {
      ...this.clientOptions,
      body: JSON.stringify({
        query: this.cartDiscountCodesUpdateMutation(cartId, discountCode)
      })
    }

    return new Promise((resolve, reject) => fetch(this.endpoint, options)
      .then(response => {
        response.json().then((resp) => resolve(resp.data)).catch(reject);
      }).catch(reject)
    );
  }

  storeCartURL = (sessionCart, options) => {
    let cartURL;

    const discountCode = options.discountCode;

    // Outside Shopify Store
    if (!this.isWithinOnlineStore()) {
      const mappedProductsUrl = sessionCart?.lines?.map((productVariant, index) => {
        let productID = productVariant.id.replace('gid://shopify/ProductVariant/', '');
        let productQuantity = productVariant.quantity;
        let productSellingPlanID = productVariant.sellingPlan ? productVariant.sellingPlan.id.replace('gid://shopify/SellingPlan/', '') : undefined;

        let output = `items[${index}][id]=${productID}%26items[${index}][quantity]=${productQuantity}`
        if (productSellingPlanID) output += `%26items[${index}][selling_plan]=${productSellingPlanID}`

        return output;
      }).join('%26');

      const baseStoreUrl = window.convertflow?.app?.shopify_store_url || window.location.host;

      cartURL = `${baseStoreUrl}/cart/clear?return_to=/cart/add?${mappedProductsUrl}${discountCode ? `%26return_to=/discount/${discountCode}?redirect=/cart` : ''}`;
    }

    // Within Shopify Store
    if (this.isWithinOnlineStore()) {
      if (!discountCode) {
        cartURL = `${window.Shopify.shop}/cart`;
      } else {
        cartURL = `${window.Shopify.shop}/discount/${discountCode}?redirect=/cart`;
      }
    }

    return cartURL;
  }

  storeCheckoutURL = (sessionCart, options) => {
    let checkoutURL;

    const discountCode = options.discountCode;

    // Outside Shopify Store
    if (!this.isWithinOnlineStore()) {
      checkoutURL = sessionCart.checkoutUrl;
    }

    // Within Shopify Store
    if (this.isWithinOnlineStore()) {
      if (!discountCode) {
        checkoutURL = `${window.Shopify.shop}/checkout`;
      } else {
        checkoutURL = `${window.Shopify.shop}/discount/${discountCode}?redirect=/checkout`;
      }
    }

    return checkoutURL;
  }

  // Helpers

  parsedProductSortKey = (sort) => {
    if (!sort) return `,sortKey: TITLE`;

    let queryArgs = '';
    if (sort == 'PRICE_ASC') queryArgs += `,sortKey: PRICE`;
    else if (sort == 'PRICE_DESC') queryArgs += `,sortKey: PRICE, reverse: true`;
    else if (sort) queryArgs += `,sortKey: ${sort}`;

    return queryArgs;
  }

  // Queries

  getProductQuery = (handle) => {
    return `
      query {
        productByHandle(handle: "${handle}") {
          availableForSale
          requiresSellingPlan
          createdAt
          description
          descriptionHtml
          featuredImage {
            url
          }
          images(first: 10) {
            edges {
                node {
                    transformedSrc
                    url
                }
            }
        }
          handle
          id
          options {
            id
            name
            values
          }
          tags
          title
          productType
          onlineStoreUrl
          vendor
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          sellingPlanGroups(first: 10) {
            edges {
                node {
                    name
                    options {
                        name
                        values
                    }
                    sellingPlans(first: 10) {
                        edges {
                            node {
                                id
                                name
                                options {
                                    name
                                    value
                                }
                            }
                        }
                    }
                }
            }
          }
          variants(first: 250) {
            edges {
                node {
                    id
                    availableForSale
                    compareAtPrice {
                        amount
                        currencyCode
                    }
                    product {
                        images(first: 10) {
                            edges {
                                node {
                                    url
                                }
                            }
                        }
                    }
                    image {
                        url
                    }
                    price {
                        amount
                    }
                    quantityAvailable
                    title
                    selectedOptions {
                      name
                      value
                    }
                    product {
                      handle
                      id
                      title
                      options {
                          id
                          name
                          values
                      }
                    }
                }
            }
          }
        }
      }
    `
  }

  getProductsQuery = (args) => {
    let queryArgs = ''

    queryArgs += `first: ${args.limit}`
    if (args?.cursor) queryArgs += `,after: "${args.cursor}"`
    if (args?.query) queryArgs += `,query: "${args.query}"`

    return `
      query {
        products(${queryArgs} ${this.parsedProductSortKey(args.sort)}) {
          edges {
            cursor
            node {
              availableForSale
              requiresSellingPlan
              totalInventory
              priceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              createdAt
              description
              descriptionHtml
              featuredImage {
                altText
                url
                width
              }
              handle
              id
              collections(first: 10) {
                edges {
                  node {
                    id
                    handle
                    title
                  }
                }
              }
              images(first: 10) {
                edges {
                  node {
                    altText
                    id
                    originalSrc
                    transformedSrc
                    width
                  }
                }
              }
              options {
                id
                name
                values
              }
              publishedAt
              tags
              title
              productType
              onlineStoreUrl
              vendor
              sellingPlanGroups(first: 10) {
                edges {
                    node {
                        name
                        options {
                            name
                            values
                        }
                        sellingPlans(first: 10) {
                            edges {
                                node {
                                    id
                                    name
                                    options {
                                        name
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
              }
              variants(first: 250) {
                edges {
                    node {
                        id
                        availableForSale
                        compareAtPrice {
                            amount
                            currencyCode
                        }
                        image {
                            url
                        }
                        price {
                            amount
                        }
                        quantityAvailable
                        title
                        selectedOptions {
                          name
                          value
                        }
                        product {
                          handle
                          id
                          title
                          options {
                              id
                              name
                              values
                          }
                        }
                    }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `;
  }

  getProductRecommendationsQuery = (productId, args) => {
    const parsedProductId = productId.toString().includes('gid://shopify/Product/') ? productId : `gid://shopify/Product/${productId}`

    let queryArgs = ''

    if (args?.intent) queryArgs += `,intent: ${args.intent.toUpperCase()}`

    return `
      query {
        productRecommendations(productId: "${parsedProductId}" ${queryArgs}) {
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          createdAt
          description
          descriptionHtml
          featuredImage {
            altText
            url
            width
          }
          handle
          id
          images(first: 10) {
            edges {
              node {
                altText
                id
                originalSrc
                transformedSrc
                width
              }
            }
          }
          options {
            id
            name
            values
          }
          publishedAt
          tags
          title
          productType
          onlineStoreUrl
          vendor
          variants(first: 50) {
          edges {
            node {
              id
              availableForSale
              compareAtPrice {
                  amount
                  currencyCode
              }
              image {
                  url
              }
              price {
                  amount
              }
              quantityAvailable
              title
              selectedOptions {
                name
                value
              }
              product {
                handle
                id
                title
                requiresSellingPlan
                options {
                    id
                    name
                    values
                }
              }
            }
          }
        }
      }
    }
    `
  }

  getCollectionProductsQuery = (collectionId, args) => {
    let queryArgs = ''
    queryArgs += `handle: "${collectionId}"`

    return `
      query {
        collectionByHandle(${queryArgs}) {
          id
          products(first: ${args.limit} ${this.parsedProductSortKey(args.sort)}) {
            nodes {
              availableForSale
              requiresSellingPlan
              priceRange {
                maxVariantPrice {
                    amount
                    currencyCode
                }
                minVariantPrice {
                    amount
                    currencyCode
                }
              }
              sellingPlanGroups(first: 10) {
                edges {
                    node {
                        name
                        options {
                            name
                            values
                        }
                        sellingPlans(first: 10) {
                            edges {
                                node {
                                    id
                                    name
                                    options {
                                        name
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
              }
              createdAt
              description
              descriptionHtml
              featuredImage {
                altText
                url
                width
              }
              handle
              id
              images(first: 10) {
                edges {
                  node {
                    altText
                    id
                    originalSrc
                    transformedSrc
                    width
                  }
                }
              }
              options {
                id
                name
                values
              }
              publishedAt
              tags
              title
              productType
              onlineStoreUrl
              vendor
              variants(first: 50) {
              edges {
                node {
                  id
                  availableForSale
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                  image {
                    url
                  }
                  price {
                    amount
                  }
                  title
                  selectedOptions {
                    name
                    value
                  }
                  product {
                    handle
                    id
                    title
                    options {
                        id
                        name
                        values
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `;
  }

  // Mutations

  cartLinesRemoveMutation = (cartId, product_ids) => {
    let parsedLines = '['

    product_ids.forEach((product, index) => {
      parsedLines += `"${product}"`

      if (index < product_ids.length - 1) parsedLines += ','
    })
    parsedLines += ']'

    return `
      mutation {
        cartLinesRemove(cartId: "${cartId}", lineIds: ${parsedLines}) {
          cart {
            id
            checkoutUrl
            createdAt
            note
            totalQuantity
            updatedAt
            lines(first: 50) {
              edges {
                  node {
                      id
                      cost {
                          totalAmount {
                              amount
                              currencyCode
                          }
                      }
                      merchandise {
                          ... on ProductVariant {
                              id
                              compareAtPrice {
                                amount
                                currencyCode
                              }
                              product {
                                  productType
                                  handle
                                  title
                                  id
                              }
                          }
                      }
                      quantity
                      sellingPlanAllocation {
                        sellingPlan {
                            description
                            id
                            name
                            recurringDeliveries
                        }
                      }
                  }
              }
            }
          }
        }
      }
    `
  }

  cartLinesAddMutation = (cartId, products) => {
    let parsedLines = '['

    products.forEach((product, index) => {
      parsedLines += `{ quantity: ${product.quantity}, merchandiseId: "${product.merchandiseId}"`
      if (product.sellingPlanId) parsedLines += `,sellingPlanId: "${product.sellingPlanId}"`
      parsedLines += '}'
      if (index < products.length - 1) parsedLines += ','
    })
    parsedLines += ']'

    return `
      mutation {
        cartLinesAdd(cartId: "${cartId}", lines: ${parsedLines}) {
          cart {
            id
            checkoutUrl
            createdAt
            note
            totalQuantity
            updatedAt
            lines(first: 250) {
              edges {
                  node {
                      id
                      cost {
                          totalAmount {
                              amount
                              currencyCode
                          }
                      }
                      merchandise {
                          ... on ProductVariant {
                              id
                              compareAtPrice {
                                  amount
                                  currencyCode
                              }
                              product {
                                  productType
                                  handle
                                  title
                                  id
                              }
                          }
                      }
                      quantity
                      sellingPlanAllocation {
                        sellingPlan {
                            description
                            id
                            name
                            recurringDeliveries
                        }
                      }
                  }
              }
            }
          }
        }
      }
    `
  }

  cartCreateMutation = () => {
    return `
      mutation {
        cartCreate {
          cart {
            id
            checkoutUrl
            createdAt
            note
            totalQuantity
            updatedAt
          }
        }
      }
    `
  }

  cartDiscountCodesUpdateMutation = (cartId, discountCode) => {
    return `
      mutation {
        cartDiscountCodesUpdate(cartId: "${cartId}", discountCodes: "${discountCode}") {
          cart {
            checkoutUrl
            createdAt
            id
            note
            totalQuantity
            updatedAt
            cost {
              checkoutChargeAmount {
                amount
                currencyCode
              }
            }
            lines(first: 3) {
              nodes {
                id
                merchandise
                quantity
              }
            }
          }
        }
      }
    `
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.ShopifyGraphqlClient = ShopifyGraphqlClient;
}
